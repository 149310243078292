import { useMediaQuery } from "@mui/material";
import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OverFooter } from "../../components/OverFooter/OverFooter";
import { isSafari } from "../../helpers/isSafari";
import { CareerPageContext } from "../../helpers/pagesContext";
import { INSPIRATIONS } from "../../helpers/route";
import Adventure from "./components/Adventure/Adventure";
import Boarding from "./components/Boarding/Boarding";
import { CarouselHero } from "./components/CarouselHero/CarouselHero";
import CompareSlider from "./components/CompareSlider/CompareSlider";
import Complete from "./components/Complete/Complete";
import OurPromise from "./components/OurPromise/OurPromise";
import StoryTelling from "./components/StoryTelling/StoryTelling";
import Testimonials from "./components/Testimonials/Testimonials";

export default function Career() {
  const { footer, node_locale, footerTitle, footerLabel } =
    useContext(CareerPageContext);

  const md = useMediaQuery("(max-width: 1199.98px)");

  return (
    <>
      {md || isSafari() ? <CarouselHero /> : <CompareSlider />}
      <Adventure />
      <Boarding />
      <OurPromise />
      <StoryTelling />
      <Complete />
      <Testimonials />
      <OverFooter
        title={footerTitle}
        buttonLink={INSPIRATIONS}
        buttonLabel={footerLabel}
        image={footer.file.url}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </>
  );
}
