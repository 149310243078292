import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { CareerPageContext } from "../helpers/pagesContext";
import { CAREER } from "../helpers/route";
import CareerScene from "../scenes/Career/Career";

export default function Career(props) {
  return (
    <Layout currentRoute={CAREER} data={props.data.contentfulMenuLabel}>
      <Seo
        title="Carrière"
        description="Zenity est en recherche continue de nouveaux talents. Envie d'apprendre, de transmettre, et de vous adapter dans la bonne humeur, devenez une pépite et rejoignez nos étoiles"
      />
      <CareerPageContext.Provider value={props.data.contentfulCareerPage}>
        <CareerScene />
      </CareerPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulCareerPage(node_locale: { eq: $locale }) {
      aventure
      node_locale
      likeTo
      reveal
      hastedTo
      succeed
      searchingFutur
      stars
      jobOne
      jobTwo
      jobThree
      jobFour
      jobFive
      jobSix
      matchCv
      offers
      promise
      welcome
      board
      atZenSince
      inspirations
      explorer
      testimonial
      footerTitle
      footerLabel
      share
      careerHeroClip {
        text {
          childMdx {
            body
          }
        }
        catchphrase
        title
        image {
          file {
            url
          }
        }
      }
      aventureText {
        aventureText
      }
      aventureTitle
      footer {
        file {
          url
        }
      }
      image {
        file {
          url
        }
      }
      storyStory {
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        title
        author
        quote
        storyTitle
        storyDescription {
          storyDescription
        }
      }
      collaboratorTestimonials {
        hiring(formatString: "D/M/Y")
        author
        oldness
        job
        text {
          childMdx {
            body
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      promiseBackground {
        file {
          url
        }
      }
      promesse {
        youtubeUrl
        thumbnail {
          gatsbyImageData(width: 780, height: 400, placeholder: BLURRED)
        }
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
