import { useMediaQuery } from "@mui/material";
import React from "react";

import { isSafari } from "../../../../../helpers/isSafari";
import * as styles from "./BodyQuote.module.css";
import Numbers from "./Numbers";

export default function BodyQuote({ number, text, author, children }) {
  const xs = useMediaQuery("(max-width: 991.98px)");

  return (
    <div className={styles.storyBodyQuote}>
      <div className={styles.storyBodyQuoteNumber}>
        <div className={styles.number}>
          {xs || isSafari() ? `0${number}` : <Numbers number={number} />}
        </div>
        <div className={styles.image}>{children}</div>
      </div>
      <div className={styles.storyBodyQuoteText}>
        “{text}”<div className={styles.storyBodyQuoteAuthor}>{author}</div>
      </div>
    </div>
  );
}
