// extracted by mini-css-extract-plugin
export var container = "Numbers-module--container--ca9cc";
export var drawingLeftFour = "Numbers-module--drawingLeftFour--df34a";
export var drawingLeftIntFour = "Numbers-module--drawingLeftIntFour--3c726";
export var drawingLeftThree = "Numbers-module--drawingLeftThree--20bda";
export var drawingLeftTwo = "Numbers-module--drawingLeftTwo--2ee02";
export var drawingLeftUn = "Numbers-module--drawingLeftUn--b903b";
export var drawingLeftZero = "Numbers-module--drawingLeftZero--65721";
export var drawingLeftZeroInt = "Numbers-module--drawingLeftZeroInt--f51f2";
export var drawingRightFour = "Numbers-module--drawingRightFour--ea6a9";
export var drawingRightIntFour = "Numbers-module--drawingRightIntFour--d51a0";
export var drawingRightThree = "Numbers-module--drawingRightThree--5ee14";
export var drawingRightTwo = "Numbers-module--drawingRightTwo--f77bb";
export var drawingRightUn = "Numbers-module--drawingRightUn--e6c65";
export var drawingRightZero = "Numbers-module--drawingRightZero--da744";
export var drawingRightZeroInt = "Numbers-module--drawingRightZeroInt--a0bc7";
export var fourIntLeft = "Numbers-module--fourIntLeft--cd759";
export var fourIntRight = "Numbers-module--fourIntRight--b008f";
export var fourLeft = "Numbers-module--fourLeft--d07e1";
export var fourRight = "Numbers-module--fourRight--982f2";
export var threeLeft = "Numbers-module--threeLeft--1f489";
export var threeRight = "Numbers-module--threeRight--7e6c5";
export var twoLeft = "Numbers-module--twoLeft--b8033";
export var twoRight = "Numbers-module--twoRight--36568";
export var unLeft = "Numbers-module--unLeft--7227c";
export var unRight = "Numbers-module--unRight--2b170";
export var zeroIntLeft = "Numbers-module--zeroIntLeft--15e38";
export var zeroIntRight = "Numbers-module--zeroIntRight--d88bd";
export var zeroLeft = "Numbers-module--zeroLeft--fcb21";
export var zeroRight = "Numbers-module--zeroRight--150cf";