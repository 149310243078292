import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useRef } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { CareerPageContext } from "../../../../helpers/pagesContext";
import BodyQuote from "./components/BodyQuote";
import * as styles from "./StoryTelling.module.css";

const Story = ({ story, number }) => {
  const domRef = useRef();
  const lastIndex = 1;
  const firstIndex = 4;

  return (
    <div
      ref={domRef}
      className={clsx(
        styles.storyFrameContainer,
        number === lastIndex && styles.lastStoryFrameContainer,
        styles.viewedFrame,
      )}
    >
      <div
        className={clsx(
          styles.storyHeader,
          number === firstIndex && styles.storyHeaderPaddingMin,
        )}
      >
        <h3
          dangerouslySetInnerHTML={{
            __html: story.storyTitle,
          }}
        />
      </div>
      <div className={styles.storyBody}>
        <SlideTransition delay={0.2} thresholds={200}>
          <BodyQuote number={number} text={story.quote} author={story.author}>
            <GatsbyImage
              image={story.image.gatsbyImageData}
              alt="story image"
            />
          </BodyQuote>
        </SlideTransition>
        <SlideTransition delay={0.4} thresholds={200}>
          <div className={styles.storyBodyText}>
            <h2 className={styles.storyBodyTextTitle}>{story.title}</h2>
            <span
              dangerouslySetInnerHTML={{
                __html: story.storyDescription.storyDescription,
              }}
            />
          </div>
        </SlideTransition>
      </div>
    </div>
  );
};

export default function StoryTelling() {
  const { storyStory } = useContext(CareerPageContext);

  return (
    <section className={styles.storyContainer}>
      {storyStory.map((story, i) => {
        return <Story key={i} story={story} number={storyStory.length - i} />;
      })}
    </section>
  );
}
