// extracted by mini-css-extract-plugin
export var arrows = "Testimonials-module--arrows--77d9c";
export var content = "Testimonials-module--content--b62a6";
export var image = "Testimonials-module--image--25eb6";
export var item = "Testimonials-module--item--c32fa";
export var name = "Testimonials-module--name--8c807";
export var paragraph = "Testimonials-module--paragraph--e28b0";
export var pointer = "Testimonials-module--pointer--ee850";
export var root = "Testimonials-module--root--800a9";
export var sign = "Testimonials-module--sign--58d55";
export var testimonials = "Testimonials-module--testimonials--9ecea";
export var text = "Testimonials-module--text--07fb6";
export var work = "Testimonials-module--work--baaaf";