import React, { useContext, useState } from "react";

import { ModalViewer } from "../../../../components/ModalViewer/ModalViewer";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { CareerPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./OurPromise.module.css";

export default function OurPromise() {
  const [stack, setStack] = useState(true);
  const { promiseBackground, promesse, promise } =
    useContext(CareerPageContext);

  return (
    <section
      className={styles.promiseContent}
      style={{
        background: `linear-gradient(#c7c7c7, #c7c7c7), url("${promiseBackground.file.url}")`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "1px 10%, cover",
        backgroundPosition: "center bottom, top left",
      }}
    >
      <div style={{ zIndex: stack ? 10 : 20 }}>
        <SlideTransition delay={0.2} thresholds={200}>
          <ModalViewer
            image={promesse.thumbnail.gatsbyImageData}
            video={promesse.youtubeUrl}
            setStack={setStack}
          />
        </SlideTransition>
      </div>
      <div
        className={styles.promiseBigTitle}
        style={{ zIndex: stack ? 20 : 10 }}
      >
        {promise}
      </div>
    </section>
  );
}
