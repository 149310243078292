// extracted by mini-css-extract-plugin
export var containerPresentation = "Boarding-module--containerPresentation--39eb2";
export var contentPresentation = "Boarding-module--contentPresentation--d43e2";
export var footerPresentation = "Boarding-module--footerPresentation--c80ed";
export var headerPresentation = "Boarding-module--headerPresentation--c75fe";
export var nectarScrollingTextAnimation = "Boarding-module--nectar-scrolling-text-animation--dd8ab";
export var presentation = "Boarding-module--presentation--a1708";
export var scrollingPresentation = "Boarding-module--scrollingPresentation--99429";
export var simpleBorderButton = "Boarding-module--simpleBorderButton--a6714";
export var simpleBorderButtonSafari = "Boarding-module--simpleBorderButtonSafari--ea715";
export var soloScrollingPresentation = "Boarding-module--soloScrollingPresentation--0cdb4";
export var stars = "Boarding-module--stars--21169";
export var stars1 = "Boarding-module--stars1--d27ee";
export var stars2 = "Boarding-module--stars2--1743f";
export var stars3 = "Boarding-module--stars3--a02db";
export var stars4 = "Boarding-module--stars4--677f1";