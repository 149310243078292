import clsx from "clsx";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useRef, useState } from "react";

import { BorderButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { CareerPageContext } from "../../../../helpers/pagesContext";
import { OPPORTUNITY } from "../../../../helpers/route";
import stars from "../../../../resources/images/career-boarding/stars.png";
import MatchYourResume from "../../../MatchYourResume/MatchYourResume";
import { isSafari } from "./../../../../helpers/isSafari";
import * as styles from "./Boarding.module.css";

export default function Boarding({ onlyScroll = false }) {
  const [openMatchYourResume, setOpenMatchYourResume] = useState(false);
  const ref = useRef();
  const keys = ["en", "es"];
  const pathname =
    typeof window !== "undefined" ? window.location.pathname : "";
  const lang = keys.find(
    (k) => pathname.includes(`/${k}/`) || pathname.includes(`/${k}`)
  );

  const {
    searchingFutur,
    jobOne,
    jobTwo,
    jobThree,
    jobFour,
    jobFive,
    jobSix,
    matchCv,
    offers,
    stars: starText,
    node_locale,
  } = useContext(CareerPageContext);

  const handleCloseMatchYourResume = () => {
    setOpenMatchYourResume(false);
  };

  return !onlyScroll ? (
    <>
      <SlideTransition delay={0.2} thresholds={200}>
        <section ref={ref} className={styles.presentation}>
          <div className={styles.scrollingPresentation}>
            {Array(3)
              .fill()
              .map((_, index) => (
                <div key={index} style={{ marginLeft: 35, marginRight: 35 }}>
                  {lang === "en" ? (
                    <StaticImage
                      src="../../../../resources/images/career-boarding/boardingPassUs.png"
                      alt="background"
                    />
                  ) : (
                    <StaticImage
                      src="../../../../resources/images/career-boarding/boardingPassFr.png"
                      alt="background"
                    />
                  )}
                </div>
              ))}
          </div>
          <div className={styles.containerPresentation}>
            <div className={styles.headerPresentation}>
              {searchingFutur}
              <span> {starText}</span>
              <motion.img
                animate={{ y: 15 }}
                transition={{
                  yoyo: Infinity,
                  duration: 5,
                  ease: "easeInOut",
                }}
                src={stars}
                width="43"
                height="43"
                alt="stars"
                className={clsx(styles.stars, styles.stars1)}
              />
              <motion.img
                animate={{ y: 25 }}
                transition={{
                  yoyo: Infinity,
                  duration: 7,
                  ease: "easeInOut",
                }}
                src={stars}
                width="18"
                height="18"
                alt="stars"
                className={clsx(styles.stars, styles.stars2)}
              />
              <motion.img
                animate={{ y: 15 }}
                transition={{
                  yoyo: Infinity,
                  duration: 5,
                  ease: "easeInOut",
                }}
                src={stars}
                width="14"
                height="14"
                alt="stars"
                className={clsx(styles.stars, styles.stars3)}
              />
              <motion.img
                animate={{ y: 10 }}
                transition={{
                  yoyo: Infinity,
                  duration: 3,
                  ease: "easeInOut",
                }}
                src={stars}
                width="29"
                height="29"
                alt="stars"
                className={clsx(styles.stars, styles.stars4)}
              />
            </div>
            <div className={styles.contentPresentation}>
              <div>
                {jobOne}
                <br />
                {jobTwo}
              </div>
              <div>{jobThree}</div>
              <div>{jobFive}</div>
            </div>
            <div className={styles.contentPresentation}>
              <div>{jobFour}</div>
              <div>{jobSix}</div>
            </div>
            <div className={styles.footerPresentation}>
              <div
                className={styles.simpleBorderButton}
                onClick={() => setOpenMatchYourResume(true)}
              >
                {matchCv}
              </div>
              {isSafari() ? (
                <div
                  className={styles.simpleBorderButtonSafari}
                  onClick={() => {
                    navigate(localeLink(OPPORTUNITY));
                  }}
                >
                  {offers}
                </div>
              ) : (
                <BorderButton
                  label={offers}
                  wide={true}
                  width={6}
                  responsive={false}
                  onClick={() => {
                    navigate(localeLink(OPPORTUNITY));
                  }}
                />
              )}
            </div>
          </div>
        </section>
      </SlideTransition>
      <MatchYourResume
        open={openMatchYourResume}
        handleClose={handleCloseMatchYourResume}
        label={"label"}
        lang={node_locale}
      />
    </>
  ) : (
    <SlideTransition delay={0.2} thresholds={200}>
      <section ref={ref} className={styles.presentation}>
        <div
          className={clsx(
            styles.scrollingPresentation,
            styles.soloScrollingPresentation
          )}
        >
          {Array(3)
            .fill()
            .map((_, index) => (
              <div key={index} style={{ marginLeft: 35, marginRight: 35 }}>
                <StaticImage
                  src="../../../../resources/images/career-boarding/background.png"
                  alt="background"
                />
              </div>
            ))}
        </div>
      </section>
    </SlideTransition>
  );
}
