/* eslint-disable import/no-unresolved */
import React, { useContext } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { CareerPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./CarouselHero.module.css";
import { CarouselHeroItem } from "./components/CarouselHeroItem";

export const CarouselHero = () => {
  const { careerHeroClip, likeTo, reveal, hastedTo, succeed } =
    useContext(CareerPageContext);

  const swiperSettings = {
    modules: [Pagination],
    grabCursor: true,
    spaceBetween: 15,
    slidesPerView: 1,
    slidesPerGroup: 1,
    pagination: {
      clickable: true,
      renderBullet: (_, className) =>
        `<div class="${className}"><div class="color"></div></div>`,
    },
  };

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <Swiper {...swiperSettings}>
          {careerHeroClip.map(({ image, title, text: { childMdx } }, i) => (
            <SwiperSlide key={i}>
              <CarouselHeroItem
                image={image}
                first={i ? hastedTo : likeTo}
                second={i ? succeed : reveal}
                title={title}
                text={childMdx}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
