import clsx from "clsx";
import { motion } from "framer-motion";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { CareerPageContext } from "../../../../helpers/pagesContext";
import stars from "../../../../resources/images/career-welcome/stars.png";
import * as styles from "./Complete.module.css";

export default function Complete() {
  const { image } = useContext(CareerPageContext);

  return (
    <div
      className={styles.root}
      style={{
        background: `linear-gradient(#c7c7c7, #c7c7c7), url(${image.file.url})`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "1px 10%, cover",
        backgroundPosition: "center top, center center",
      }}
    >
      <div>
        <SlideTransition delay={0.2} thresholds={200}>
          <p>
            <span>Welcome</span> <br /> on board
          </p>
        </SlideTransition>
        <motion.img
          animate={{ y: 15 }}
          transition={{
            yoyo: Infinity,
            duration: 5,
            ease: "easeInOut",
          }}
          src={stars}
          width="57"
          height="54"
          alt="stars"
          className={clsx(styles.stars, styles.stars1)}
        />
        <motion.img
          animate={{ y: 25 }}
          transition={{
            yoyo: Infinity,
            duration: 7,
            ease: "easeInOut",
          }}
          src={stars}
          width="25"
          height="24"
          alt="stars"
          className={clsx(styles.stars, styles.stars2)}
        />
        <motion.img
          animate={{ y: 15 }}
          transition={{
            yoyo: Infinity,
            duration: 5,
            ease: "easeInOut",
          }}
          src={stars}
          width="40"
          height="38"
          alt="stars"
          className={clsx(styles.stars, styles.stars3)}
        />
        <motion.img
          animate={{ y: 10 }}
          transition={{
            yoyo: Infinity,
            duration: 3,
            ease: "easeInOut",
          }}
          src={stars}
          width="20"
          height="19"
          alt="stars"
          className={clsx(styles.stars, styles.stars4)}
        />
      </div>
    </div>
  );
}
