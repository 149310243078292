import "moment/locale/fr";

import { MDXProvider } from "@mdx-js/react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { Arrows } from "../../../../components/Arrows/Arrows";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { CareerPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Testimonials.module.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ContentDraw = ({ paragraph, name, job, hiring, children }) => {
  const { atZenSince, node_locale } = useContext(CareerPageContext);

  if (node_locale === "fr-FR") moment.locale("fr");
  else moment.locale("en");
  const date = moment(hiring, "D/M/YYYY");

  return (
    <div className={styles.content}>
      <div className={styles.item}>
        <div className={styles.image}>
          <SlideTransition delay={0.4} thresholds={200}>
            <StaticImage
              src="../../../../resources/images/inside-zenity/partner/vector.png"
              alt="vector"
            />
          </SlideTransition>
          {children}
        </div>
        <div className={styles.text}>
          <MDXProvider>
            <article className={styles.paragraph}>
              <MDXRenderer>{paragraph}</MDXRenderer>
            </article>
          </MDXProvider>
          <div className={styles.sign}>
            <div className={styles.name}>
              <span>{name}</span>, {job}
            </div>
            <div className={styles.work}>
              {atZenSince} {date.fromNow(true)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Testimonials() {
  const { collaboratorTestimonials, testimonial, share } =
    useContext(CareerPageContext);
  const [value, setValue] = useState(0);

  const handleChangeIndex = (index) => {
    setTimeout(() => {
      setValue(index);
    }, 250);
  };

  return (
    <>
      <div className={styles.testimonials}>
        <h2>{share}</h2>
        <SlideTransition delay={0.2} thresholds={200}>
          <h3>{testimonial}</h3>
        </SlideTransition>
        <div className={styles.root}>
          <SwipeableViews
            index={value}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
            springConfig={{
              duration: "0.5s",
              easeFunction: "ease-out",
              delay: "0s",
            }}
            className={styles.pointer}
          >
            {collaboratorTestimonials.map((item, index) => (
              <TabPanel key={index} value={value} index={index}>
                <ContentDraw
                  paragraph={item.text.childMdx.body}
                  name={item.author}
                  job={item.job}
                  hiring={item.hiring}
                >
                  <GatsbyImage image={item.image.gatsbyImageData} alt="main" />
                </ContentDraw>
              </TabPanel>
            ))}
          </SwipeableViews>
        </div>
        <div className={styles.arrows}>
          <Arrows
            value={value}
            setValue={setValue}
            max={collaboratorTestimonials.length}
          />
        </div>
      </div>
    </>
  );
}
