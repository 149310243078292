// extracted by mini-css-extract-plugin
export var frameLineDraw = "StoryTelling-module--frameLineDraw--556ca";
export var lastStoryFrameContainer = "StoryTelling-module--lastStoryFrameContainer--a95ad";
export var storyBody = "StoryTelling-module--storyBody--868a9";
export var storyBodyText = "StoryTelling-module--storyBodyText--9f373";
export var storyBodyTextTitle = "StoryTelling-module--storyBodyTextTitle--29ab0";
export var storyContainer = "StoryTelling-module--storyContainer--b9f4c";
export var storyFrameContainer = "StoryTelling-module--storyFrameContainer--b498e";
export var storyHeader = "StoryTelling-module--storyHeader--fc8d7";
export var storyHeaderPaddingMin = "StoryTelling-module--storyHeaderPaddingMin--c7051";
export var viewedFrame = "StoryTelling-module--viewedFrame--9f216";