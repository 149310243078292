import { MDXProvider } from "@mdx-js/react";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../../components/SlideTransition/SlideTransition";
import { CareerPageContext } from "../../../../../helpers/pagesContext";
import * as styles from "./CarouselHeroItem.module.css";

export const CarouselHeroItem = ({ image, first, second, title, text }) => {
  const { node_locale } = useContext(CareerPageContext);

  return (
    <div className={styles.item}>
      <div
        className={styles.background}
        style={{
          background: `linear-gradient(180deg,rgba(63,65,79,.53) 100%,rgba(0,0,0,.16) 0), url(${image.file.url}) center center/ cover no-repeat`,
        }}
      />
      <SlideTransition delay={0.4}>
        <h2 className={styles.catchphrase}>
          <span>{`${first}`}</span> <br /> {`${second}`}
          <span>{node_locale === "fr-FR" ? " ?" : "?"}</span>
        </h2>
      </SlideTransition>
      <SlideTransition delay={0.6}>
        <div className={styles.data}>
          <div className={styles.title}>{title}</div>
          <MDXProvider>
            <div className={styles.text}>
              <MDXRenderer>{text.body}</MDXRenderer>
            </div>
          </MDXProvider>
        </div>
      </SlideTransition>
    </div>
  );
};

CarouselHeroItem.propTypes = {
  image: PropTypes.object.isRequired,
  catchphrase: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
};
