import React, { useRef } from "react";

import { useInView } from "../../../../../helpers/useInView";
import * as styles from "./Numbers.module.css";

export default function Numbers({ number }) {
  const domRef = useRef();
  const inView = useInView(domRef, 300);

  const stroke = "#C4C4C4";
  const fill = "transparent";
  const strokeFill = "3";
  const strokeFillBase = "0";
  const svg = {
    ZERO: "M 51.2788 414.1057 c -0.6118 -0.6895 -1.214 -1.3985 -1.8161 -2.1075 L 47.6854 409.8422 Q 0.0777 351.6679 0.0777 241.7195 q 0 -114.0177 52.5705 -177.8637 T 182.0301 0 q 76.8017 0 128.3525 59.7476 T 361.9334 232.1144 q 0 112.658 -52.5705 176.4944 T 179.6702 472.4839 q -77.161 0 -128.3622 -58.3782",
    ZERO_I:
      "M 227.1364 439.757 l 0.8589 -0.5344 Q 251.6633 424.2106 271.1226 394.1484 q 38.909 -60.0384 38.909 -165.7141 T 272.1247 66.4422 q -37.907 -56.3642 -93.25 -56.3069 T 84.6131 70.4695 q -38.9186 60.382 -38.9186 167.012 T 83.2674 399.2161 Q 120.8308 454.2728 176.5556 454.2728 A 94.1757 94.1757 90 0 0 227.1364 439.757 Z",
    ONE: "M.87,38.67,15.29,35.4.87,38.67.16,25.31,112.64,0h44.29V465.39H112.64v-452l-97.35,22Z",

    TWO: "M94.89,17.18l-4.07,1A377.32,377.32,0,0,0,36.76,36.48L17.08,44.22l-3.51-7Q42.39,21.73,79.65,10.82T147.14-.07q53.42,0,92.44,29.52t39,93.5q0,85.78-72.41,177.86-35.85,45.7-66.79,78.74t-78.73,78H277.19l14.06-116,13.36,3.51-10.55,123H7.24L-.49,457.58q118.09-114.57,175-193T231.5,122.25q0-33.75-15.47-68.19-9.15-19-28.47-30.93T142.92,11.18a212.42,212.42,0,0,0-48,6m0,0",

    THREE:
      "M98.07,16.09q-5.8,1.21-11.7,2.72Q56.15,26.55,40,34.27L23.8,42l-4.92-7Q40.67,22.32,79,11.07T148.24-.17q54.12,0,88.57,26t34.45,75.22q0,73.82-73.11,110.37A229.89,229.89,0,0,1,163,225.49q111.06,13.37,111.07,101.23,0,73.13-49.91,108.26T85,470.13A334.36,334.36,0,0,1-2.91,458.89L2,450.45q28.11,9.15,78.74,9.14,145.51,0,145.52-130.76,0-48.51-26-67.49a233.3,233.3,0,0,0-26.36-16.17q-12.32-6.31-34.1-8.43-36.56-3.51-70.3-3.52V222.68q38.66,0,58.35.7,22.49-4.22,52.72-23.9,19-12.66,30.94-36.56t12-59a115.48,115.48,0,0,0-18.63-64q-18.65-28.81-60.11-28.83A225.34,225.34,0,0,0,102,15.3Z",
    FOUR: "M 201.55 0.37 h 44 V 356.79 h 32.34 l 0.7 10.55 h -33 V 452.4 l 47.8 2.81 c 0 0.47 -0.24 4 -0.7 10.55 l -140.6 0.7 a 53 53 0 0 1 0.7 -9.84 l 36.56 -4.92 c 4.68 -0.46 7.49 -3.52 8.44 -9.14 V 367.34 H -13.12 l -12 -10.55 L 197.79 0.37 h 3.76 m 0 0",
    FOUR_I: "-14 357.77 198 357.77 198 15.74",
  };

  const switchNumber = (number) => {
    switch (number) {
      case 1:
        return (
          <>
            {inView && (
              <>
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.ONE}
                  className={styles.unRight}
                />
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.ONE}
                  className={styles.unLeft}
                />
              </>
            )}

            <path
              fill={fill}
              stroke={stroke}
              strokeWidth={strokeFillBase}
              d={svg.ONE}
            />
          </>
        );
      case 2:
        return (
          <>
            {inView && (
              <>
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.TWO}
                  className={styles.twoRight}
                />
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.TWO}
                  className={styles.twoLeft}
                />
              </>
            )}

            <path
              fill={fill}
              stroke={stroke}
              strokeWidth={strokeFillBase}
              d={svg.TWO}
            />
          </>
        );
      case 3:
        return (
          <>
            {inView && (
              <>
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.THREE}
                  className={styles.threeRight}
                />
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.THREE}
                  className={styles.threeLeft}
                />
              </>
            )}
            <path
              fill={fill}
              stroke={stroke}
              strokeWidth={strokeFillBase}
              d={svg.THREE}
            />
          </>
        );
      case 4:
        return (
          <>
            {inView && (
              <>
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.FOUR}
                  className={styles.fourRight}
                />
                <path
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  d={svg.FOUR}
                  className={styles.fourLeft}
                />
              </>
            )}
            <path
              fill={fill}
              stroke={stroke}
              strokeWidth={strokeFillBase}
              d={svg.FOUR}
            />
            {inView && (
              <>
                <polygon
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  points={svg.FOUR_I}
                  className={styles.fourIntRight}
                />
                <polygon
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeFill}
                  points={svg.FOUR_I}
                  className={styles.fourIntLeft}
                />
              </>
            )}
            <polygon
              fill={fill}
              stroke={stroke}
              strokeWidth={strokeFillBase}
              points={svg.FOUR_I}
            />
          </>
        );
    }
  };

  return (
    <div ref={domRef} className={styles.container}>
      <>
        <svg viewBox="-30 0 400 500">
          {inView && (
            <>
              <path
                fill={fill}
                stroke={stroke}
                strokeWidth={strokeFill}
                d={svg.ZERO}
                className={styles.zeroLeft}
              />
              <path
                fill={fill}
                stroke={stroke}
                strokeWidth={strokeFill}
                d={svg.ZERO}
                className={styles.zeroRight}
              />
              <path
                fill={fill}
                stroke={stroke}
                strokeWidth={strokeFill}
                d={svg.ZERO_I}
                className={styles.zeroIntLeft}
              />
              <path
                fill={fill}
                stroke={stroke}
                strokeWidth={strokeFill}
                d={svg.ZERO_I}
                className={styles.zeroIntRight}
              />
            </>
          )}
          <path
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeFillBase}
            d={svg.ZERO}
          />
          <path
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeFillBase}
            d={svg.ZERO_I}
          />
        </svg>
        <svg viewBox="-30 0 400 500">{switchNumber(number)}</svg>
      </>
    </div>
  );
}
