import { MDXProvider } from "@mdx-js/react";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import React, { useContext } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
} from "react-compare-slider";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { CareerPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./CompareSlider.module.css";

export default function CompareSlider() {
  const { careerHeroClip, likeTo, reveal, hastedTo, succeed, node_locale } =
    useContext(CareerPageContext);

  return (
    <ReactCompareSlider
      changePositionOnHover={true}
      className={styles.compareSlider}
      handle={
        <ReactCompareSliderHandle
          buttonStyle={{ display: "none" }}
          linesStyle={{ height: "100%", width: 2, cursor: "default" }}
        />
      }
      itemOne={
        <div
          className={styles.itemCompareSlider}
          style={{
            background: `linear-gradient(180deg,rgba(60,60,61,.33) 100%,rgba(0,0,0,.36) 0), url(${careerHeroClip[0].image.file.url}) center center/ cover no-repeat`,
          }}
        >
          <SlideTransition delay={0.4}>
            <h2 className={styles.itemTitle}>
              <span>{likeTo}</span> <br />
              {reveal}
              <span>{node_locale === "fr-FR" ? " ?" : "?"}</span>
            </h2>
          </SlideTransition>
          <div className={styles.itemText}>
            <div>
              <p>{careerHeroClip[0].title}</p>
              <MDXProvider>
                <div className={styles.textChildMdx}>
                  <MDXRenderer>
                    {careerHeroClip[0].text.childMdx.body}
                  </MDXRenderer>
                </div>
              </MDXProvider>
            </div>
          </div>
        </div>
      }
      itemTwo={
        <div
          style={{
            background: ` linear-gradient(180deg,rgba(0,0,0,.36) 100%,rgba(0,0,0,.36) 0), url(${careerHeroClip[1].image.file.url}) center center/ cover no-repeat`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
          }}
          className={styles.itemCompareSlider}
        >
          <div className={styles.itemText}>
            <div>
              <p>{careerHeroClip[1].title}</p>
              <MDXProvider>
                <div className={styles.textChildMdx}>
                  <MDXRenderer>
                    {careerHeroClip[1].text.childMdx.body}
                  </MDXRenderer>
                </div>
              </MDXProvider>
            </div>
          </div>
          <SlideTransition delay={0.4}>
            <h2 className={styles.itemTitle}>
              <span>{hastedTo}</span> <br />
              {succeed}
              <span>{node_locale === "fr-FR" ? " ?" : "?"}</span>
            </h2>
          </SlideTransition>
        </div>
      }
    />
  );
}
